import { Component, ElementRef, NgZone, ViewChild } from '@angular/core';
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import { ContactService } from '../services/contact.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-footer',
  standalone: true,
  templateUrl: './app.footer.component.html',
  styleUrl: './app.footer.component.css',
  imports: [
    FormsModule, ReactiveFormsModule, CommonModule
  ]
})
export class AppFooterComponent {

  contactForm: FormGroup;

  @ViewChild('divRecaptcha')
  divRecaptcha!: ElementRef<HTMLDivElement>;

  get grecaptcha(): any {
    const w = window as any;
    return w['grecaptcha'];
  }

  constructor(
    private contactService: ContactService,
    private formBuilder: FormBuilder,
    private ngZone: NgZone
  ){
    this.contactForm = this.formBuilder.group({ 
      name: ['', Validators.required], 
      email: ['', [Validators.required, Validators.email]], 
      message: ['', [Validators.required, Validators.minLength(10)]],
      recaptcha: ['', [Validators.required]]
    });

    this.renderizarReCaptcha();
  }

  renderizarReCaptcha() {
    this.ngZone.runOutsideAngular(() => {
      if (!this.grecaptcha || !this.divRecaptcha) {
        setTimeout(() => {
          this.renderizarReCaptcha();
        }, 500);

        return;
      }

      const idElemento =
        this.divRecaptcha.nativeElement.getAttribute('id');

      this.grecaptcha.render(idElemento, {
        sitekey: '6LcWkp0qAAAAAEiujh-tM_Z2AkryJ5ledeyy7t4A',
        callback: (response: string) => {
          this.ngZone.run(() => {
            this.contactForm.get('recaptcha')?.setValue(response);
          });
        },
      });
    });
  }
  
  submitContact() {
    if (this.contactForm.valid) {
      const contactValues = this.contactForm.value;
      this.contactService.sendContactFormMail(contactValues).subscribe(
      response => { 
        alert("Mensagem enviada com sucesso!"); 
        console.log("Mensagem enviada com sucesso!", response)}, 
      error => { 
        alert('Erro ao enviar e-mail'); 
        console.log("Erro ao enviar mensagem", error);
      });
      return
    }
    return alert("Preencha todos os campos necessários");
  }
}
