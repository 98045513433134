<div id="contato" class="contatoPosition">
    <div class="contato">
        <img class="contatoLogo" src="/KWaut.png">
        <div class="redesSociais">
            <a href="https://www.facebook.com/KWaut.CTBA?locale=pt_BR" target="_blank">
                <img src="/redes_sociais/facebook_logo.png" alt="ícone do Facebook">
            </a>
            <a href="https://www.instagram.com/kwaut_com/" target="_blank">
                <img src="/redes_sociais/instagram_logo.png" alt="ícone do Instagram">
            </a>
            <a href="https://www.linkedin.com/company/kwaut/" target="_blank">
                <img src="/redes_sociais/linkedin_logo.png" alt="ícone do Linkedin">
            </a>
        </div>
        <div class="contatoLines">
            <img src="/contato/localizacao.png" alt="Ícone de localização">
            <div>
                <p>Rua Professora Maria de Assumpção, 498</p>
                <p>Casa 12 - Hauer - Curitiba/PR</p>
                <p> <a href="https://www.google.com/maps/search/?api=1&query=Rua+Professora+Maria+de+Assumpção%2C+498%2C+Casa+12+-+Hauer+-+Curitiba%2FPR" target="_blank"> Ver no Google Maps </a> </p>
            </div>
        </div>
        <div class="contatoLines">
            <img src="/contato/telefone.png" alt="Ícone de telefone">
            <p><a href="tel:+554132761645">+55 (41) 3276-1645</a></p>
        </div>
        <div class="contatoLines">
            <img src="/contato/email.png" alt="Ícone de email">
            <p><a href="mailto:contato@kwaut.com">contato&#64;kwaut.com</a></p>
        </div>
    </div>
    <div class="contatoForm">
        <h1 class="tituloMenor">
            Fale Conosco
        </h1>
        <form [formGroup]="contactForm" (ngSubmit)="submitContact()"> 
            <div class="contatoFormFields"> 
                <input placeholder="Nome" class="input" type="text" formControlName="name" id="name">
                <div *ngIf="contactForm.get('name')?.invalid && contactForm.get('name')?.touched || contactForm.get('name')?.dirty">
                    <small *ngIf="contactForm.get('name')?.errors?.['required']" class="validatorText">"Nome" deve ser preenchido.</small>
                </div> 
            </div> 
            <div class="contatoFormFields"> 
                <input placeholder="Email" class="input" type="email" formControlName="email" id="email">
                <div *ngIf="contactForm.get('email')?.invalid && contactForm.get('email')?.touched || contactForm.get('email')?.dirty">
                    <small *ngIf="contactForm.get('email')?.errors?.['required']" class="validatorText">"Email" deve ser preenchido.</small>
                    <small *ngIf="contactForm.get('email')?.errors?.['email']" class="validatorText">O email fornecido não é válido.</small>
                </div> 
            </div> 
            <div class="contatoFormFields"> 
                <textarea placeholder="Mensagem" class="textarea" formControlName="message" rows="4" cols="25" id="message"></textarea>
                <div *ngIf="contactForm.get('message')?.invalid && contactForm.get('message')?.touched || contactForm.get('email')?.dirty">
                    <small *ngIf="contactForm.get('message')?.errors?.['required']" class="validatorText">"Mensagem" deve ser preenchido.</small>
                    <small *ngIf="contactForm.get('message')?.errors?.['minlength']" class="validatorText">A mensagem deve conter pelo menos 10 caracteres.</small>
                </div> 
            </div>
            <div #divRecaptcha id="recaptcha" class="recaptcha"></div>
            <button type="submit" class="button">Enviar</button>
        </form>
    </div>
    <div class="contato">
        
    </div>
</div>
<div class="footerPosition">
    <div class="LTDA">
        <p>© 2024 KWaut Sistemas de Automação Ltda.</p>
    </div>
</div>