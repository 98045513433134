import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'app-topbar',
  standalone: true,
  templateUrl: './app.topbar.component.html',
  styleUrl: './app.topbar.component.css',
  imports: [CommonModule]
})
export class AppTopbarComponent {
  menuValue:boolean=false;

  constructor(
  ){}

  openMenu(){
    this.menuValue =!this.menuValue
  }

  closeMenu(){
    this.menuValue = false;
  }

}
