import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  readonly apiURL: string;

  constructor(private http: HttpClient) {
    this.apiURL = "https://mail-api-nu.vercel.app";
  } 
  
  sendContactFormMail(contactForm:any): Observable<any> { 
    return this.http.post(`${ this.apiURL }/api/contact`, contactForm)
  }
}
