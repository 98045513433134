<nav class="topbarPosition">
    <div class="topBarContent">
        <div class="topBarImage">
            <a href="/">
                <img class="topbarLogo" src="/KWaut.png">
            </a>
        </div>
        <div class="menuConfig">
            <div class="menuIcon" (click)="openMenu()">
                <div *ngIf="!menuValue">☰</div>
                <div *ngIf="menuValue">×</div>
            </div>
            <div class="topBarMenu" [class.mobileMenu]="menuValue">
                <a href="/" (click)="closeMenu()">Início</a>
                <a href="#trabalhos" (click)="closeMenu()">Trabalhos Realizados</a>
                <a href="#clientes" (click)="closeMenu()">Nossos Clientes</a>
                <a href="#contato" (click)="closeMenu()">Contato</a>
            </div>
        </div>        
    </div>
</nav>